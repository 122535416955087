/**
 * WordPress Access (Login Screen).
 *
 * @category   JavaScript
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


var body = document.getElementsByTagName("body")[0];
var title = document.querySelector("#login").getElementsByTagName("h2")[0];
body.insertBefore(title, body.firstChild);

/**
 * Hide the {user_login} input field and populate it with a sanitized
 * version of a registerer's email address.
 */
var registrationForm = document.querySelector(".login-action-register #registerform");
var registerEmail = document.querySelector(".login-action-register #user_email");
var registerLogin = document.querySelector(".login-action-register #user_login");

if (exists(registrationForm)) {
    registerLogin.parentNode.style.display = "none";
    registrationForm.addEventListener("submit", function (evt) {
        evt.preventDefault();

        if (registerEmail.value !== '') {
            var username = sanitizeUsername(registerEmail.value);
            registerLogin.setAttribute("value", username);
        }

        this.submit();
    });
}


/**
 * "Remember me" by default.
 */
var rememberMe = document.getElementById("rememberme");
if (exists(rememberMe)) {
    rememberMe.checked = true;
}

/* <> */
